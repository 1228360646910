<template>
  <div />
</template>
<!-- <template>
  <div>
    <div class="m-count-block">

      <div class="m-count-title">
        化繁為簡，代買幫讓你專注<br>
        於最有價值的事情！
      </div>
      <div class="m-count-content">
        <div class="m-count-window">
          <div style="margin-top: 0px">
            
            <div class="m-count-number">
              <number v-observe-visibility="visibilityChanged" class="m-count-number" ref="number1" :from="19999"
                :to="user.final" :format="theFormat1" :duration="0.5" :delay="1" easing="Power1.easeOut" />+
            </div>
            <div class="m-count-text">累積出貨訂單</div>
          </div>
          <div class="m-count-line"></div>
          <div style="margin-top: 0px">
            <div class="m-count-number">
              <number v-observe-visibility="visibilityChanged" class="m-count-number" ref="number2" :from="1"
                :to="rate.rate1" :format="theFormat2" :duration="0.5" :delay="1" easing="Power1.easeOut" />%
            </div>
            <div class="m-count-text">客戶回覆率</div>
          </div>
          <div class="m-count-line"></div>
          <div style="margin-top: 0px">
            <div class="m-count-number">
              <number v-observe-visibility="visibilityChanged" class="m-count-number" ref="number3" :from="1"
                :to="rate.rate2" :format="theFormat2" :duration="0.5" :delay="1" easing="Power1.easeOut" />%
            </div>
            <div class="m-count-text">出貨率</div>
          </div>
          <div class="m-count-line"></div>
          <div style="margin-top: 0px">
            <div class="m-count-number">
              <number v-observe-visibility="visibilityChanged" class="m-count-number" ref="number4" :from="1"
                :to="rate.rate3" :format="theFormat2" :duration="0.5" :delay="1" easing="Power1.easeOut" />%
            </div>
            <div class="m-count-text">訂單評估效率</div>
          </div>
        </div>
      </div>
    </div>

    <img class="m-count-image" data-aos="zoom-in-right" src="@/assets/images/desktop/count/frame.svg" />


 
    <div class="m-recommend-block">


      <div class="m-recommend-title">客人推薦</div>

      <div class="m-recommend-windows" style="margin-top:10px;" data-aos="fade-up" data-aos-duration="500">
        <img class="m-recommend-ellipse" src="@/assets/images/mobile/recommend/ellipse1.svg" alt="代買幫" />
        <div class="m-recommend-text">
          服務親切、有耐心，詢問問題都會仔細回<br>
          覆，代購的商品若有問題，也都會協助處<br>
          理，很推薦喔❤️
          <div class="m-recommend-text-name">林佳瑩</div>
        </div>
        <img class="m-recommend-vector-d" style="margin-top: -20px; margin-left: 293px"
          src="@/assets/images/mobile/recommend/vector.svg" alt="代買幫" />
      </div>


      <div class="m-recommend-windows" data-aos="fade-up" data-aos-duration="500">
        <img class="m-recommend-vector-d" style="margin-top: 20px; margin-left: 20px"
          src="@/assets/images/mobile/recommend/vector2.svg" alt="代買幫" />
        <img class="m-recommend-ellipse-c" src="@/assets/images/mobile/recommend/ellipse2.svg" alt="代買幫" />
        <div class="m-recommend-text">
          有效溝通，且都會密切跟消費者與廠商聯<br>
          繫！讓人十分安心
          <div class="m-recommend-text-name" style="margin-top: 40px;">林庭安</div>
        </div>
      </div>



      <div class="m-recommend-windows" data-aos="fade-up" data-aos-duration="500">

        <img class="m-recommend-ellipse" src="@/assets/images/mobile/recommend/ellipse3.svg" alt="代買幫" />
        <div class="m-recommend-text">
          服務好！速度快 價格合理, 專注於 批貨平<br>
          台 👍
          <div class="m-recommend-text-name" style="margin-top: 40px;">王安婷</div>
        </div>
        <img class="m-recommend-vector-d" src="@/assets/images/mobile/recommend/vector.svg"
          style="margin-top: -20px; margin-left: 293px" alt="代買幫" />
      </div>




      <div class="m-recommend-button-bg button-pointer" style="top:15px;" @click="goChatbot()">
        <div class="m-recommend-button-content">
          <div class="m-recommend-button-text">開始使用</div>
          <img class="m-recommend-button-vector" src="@/assets/images/desktop/hero/button_vector.svg" alt="代買幫" />
        </div>
      </div>

      <div class="m-recommend-bg_r"></div>
      <div class="m-recommend-bg_b"></div>
      <img class="m-recommend-bg_com-up"
        src="@/assets/images/mobile/recommend/com.svg" />
      <img class="m-recommend-bg_com-do" src="@/assets/images/mobile/recommend/com.svg" />

    </div>


  </div>

</template>

<script>
export default {
  components: {},
  data() {
    return {
      user: {
        base: 500000,
        rate: 0.002497766,
        final: null,
      },
      rate: {
        min: 80.5,
        max: 99.9,
        rate1: null,
        rate2: null,
        rate3: null,
      },
    };
  },
  created() {
    this.user.final = Math.floor(moment().unix() * this.user.rate);
    this.rate.rate1 = this.getRandom(this.rate.min, this.rate.max);
    this.rate.rate2 = this.getRandom(this.rate.min, this.rate.max);
    this.rate.rate3 = this.getRandom(this.rate.min, this.rate.max);
  },
  mounted() { },
  methods: {
    visibilityChanged(isVisible, entry) {
      if (isVisible) {
        this.$refs.number1.restart();
        this.$refs.number2.restart();
        this.$refs.number3.restart();
        this.$refs.number4.restart();
      }
      console.log(entry);
    },
    theFormat1(number) {
      return number.toFixed(0);
    },
    theFormat2(number) {
      return number.toFixed(1);
    },
    getRandom(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
  },
};
</script>
<style scope>
.m-count-block {
  display: block;
  width: 100%;
  height: 1045px;
  margin: 0 auto;
  background: #c0e3f9;
  position: relative;
  overflow-y: visible;
  overflow-X: visible;
}

.m-count-title {
  font-family: PingFang TC;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #3a3a3a;
  margin: 0 auto;
  padding-top: 60px;
  margin-bottom: 32px;
}

.m-count-content {
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
}

.m-count-window {
  width: 343px;
  height: 816px;
  background: #ffffff;
  box-shadow: 0px 30px 56px -10px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.m-count-number {
  font-family: PingFang TC;
  font-style: normal;
  font-weight: bolder;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  color: #1c5988;
}

.m-count-text {
  font-family: PingFang TC;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 39px;
  text-align: center;
  color: #538bc6;
  margin-top: 15px;
}

.m-count-line {
  width: 290px;
  height: 0px;
  border: 1px solid #aabac6;
  transform: rotate(-180deg);
  margin: 40px 27px;
}

.m-count-image {
  position: relative;
  display: block;
  top: -50px;
  margin: 0 auto;
  right: 119px;
  width: 104px;
  height: 105px;
}





.m-recommend-block {
  width: 100%;
  height: 1149px;
  margin-top: -105px;
  background: #f7f7f7;
  overflow-y: hidden;
  overflow-x: hidden;
}

.m-recommend-bg_r {
  width: 373px;
  height: 373px;
  position: relative;
  background: radial-gradient(50% 50% at 50% 50%,
      rgba(244, 152, 183, 0.4) 0%,
      rgba(244, 152, 183, 0) 100%);
  opacity: 0.5;
  left: 170px;
  top: -1260px;
  display: block;
  margin: 0 auto;

}

.m-recommend-bg_b {
  width: 284px;
  height: 284px;
  position: relative;
  background: radial-gradient(50% 50% at 50% 50%,
      rgba(192, 227, 249, 0.4) 0%,
      rgba(192, 227, 249, 0) 100%);
  opacity: 0.9;
  left: -100px;
  top: -450px;
  display: block;
  margin: 0 auto;
}

.m-recommend-title {
  font-family: PingFang TC;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 67px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #3a3a3a;
  margin: 0 auto;
  margin-top: 55px;
}

.m-recommend-windows {
  margin: 32px auto;
  width: 343px;
  height: 260px;
  background: #ffffff;
  box-shadow: 0px 30px 56px -10px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  display: flex;
  z-index: 99;
  flex-direction: column;
}


.m-recommend-ellipse {
  margin: 20px auto;
  vertical-align: middle;
  height: 72px;
  text-align: center;
  width: 100%;
}

.m-recommend-ellipse-c {
  margin-top: -24px;
  margin-bottom: 24px;

  vertical-align: middle;
  height: 72px;
  text-align: center;
  width: 100%;
}

.m-recommend-text {
  font-family: PingFang TC;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  /* or 175% */
  text-align: center;
  color: #333333;
  margin: 2px auto;
}

.m-recommend-text-name {
  font-family: PingFang TC;
  font-style: normal;
  font-size: 14px;
  text-align: center;
  color: #333333;
  margin-top: 12px;
}

.m-recommend-bg_com-up {
  position: relative;
  margin: 0 auto;
  display: block;
  width: 100%;
  height: 180px;
  top: -1666px;
  right: -145px;
}
.m-recommend-bg_com-do {
  position: relative;
  margin: 0 auto;
  display: block;
  width: 100%;
  height: 170px;
  top: -950px;
  right: 190px;
}

.recommend-image {
  position: relative;
  display: block;
  margin: 0 auto;
  top: -71px;
  right: 120px;
}

.m-recommend-vector-d {
  width: 30px;
  height: 24px;
}

.m-recommend-button-content {
  position: relative;
  top: 14px;
  left: 45px;
}

.m-recommend-button-text {
  display: inline;
  font-family: PingFang TC;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  color: #ffffff;
  margin-right: 20px;
}

.m-recommend-button-vector {
  width: 100%;
  height: 11px;
  margin-top: -65px;
  margin-left: 15px;
}

.m-recommend-button-bg {
  display: block;
  position: relative;
  width: 216px;
  height: 62px;
  top: 10px;
  background: #5295d4;
  border-radius: 50px;
  z-index: 1;
  margin-right: 835px;
  margin: 0 auto;
}
</style> -->
