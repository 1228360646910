<script setup>
import NumberAnimation from "vue-number-animation";
import GoChatBot from "@/components/GoChatBot.vue";
</script>

<template>
  <div>
    <section style="position: relative; margin-top: -30px">
      <div class="blue-background-mo">
        <div style="display: flex; padding-top: 151px; justify-content: center">
          <img
            style=""
            src="@/assets/images/desktop/products/right.svg"
            alt=""
          />
          <p class="service-results-mo" style="">服務成果</p>
          <img
            style=""
            src="@/assets/images/desktop/products/left.svg"
            alt=""
          />
        </div>

        <!-- 數字自己跑  -->
        <div class="count-content-mo" v-observe-visibility="visibilityChanged">
          <div style="margin-top: 32px; margin-bottom: 32px">
            <div
              style="display: flex; flex-direction: column; align-items: center"
            >
              <NumberAnimation
                class="count-number-mo"
                ref="number1"
                :from="3000000"
                :to="5000000 - randomInRange(10000)"
                :format="(v) => v.toFixed(0)"
                :duration="0.5"
                :delay="1"
                easing="linear"
              />
              <div class="count-text-mo">累積出貨訂單</div>
            </div>
            <div class="count-content-line-mo"></div>

            <div
              style="display: flex; flex-direction: column; align-items: center"
            >
              <div style="display: flex; flex-direction: row">
                <NumberAnimation
                  class="count-number-mo"
                  ref="number2"
                  :from="85.5"
                  :to="99.9 - randomInRange(10)"
                  :format="theFormat"
                  :duration="0.5"
                  :delay="1"
                  easing="linear"
                />
                <p class="count-number-mo">%</p>
              </div>
              <div class="count-text-mo">客戶回覆率</div>
            </div>
            <div class="count-content-line-mo"></div>

            <div
              style="display: flex; flex-direction: column; align-items: center"
            >
              <div style="display: flex; flex-direction: row">
                <NumberAnimation
                  class="count-number-mo"
                  ref="number3"
                  :from="85.5"
                  :to="99.9 - randomInRange(10)"
                  :format="theFormat"
                  :duration="0.5"
                  :delay="1"
                  easing="linear"
                />
                <p class="count-number-mo">%</p>
              </div>
              <div class="count-text-mo">出貨率</div>
            </div>
            <div class="count-content-line-mo"></div>

            <div
              style="display: flex; flex-direction: column; align-items: center"
            >
              <div style="display: flex; flex-direction: row">
                <NumberAnimation
                  class="count-number-mo"
                  ref="number4"
                  :from="85.5"
                  :to="99.9 - randomInRange(10)"
                  :format="theFormat"
                  :duration="0.5"
                  :delay="1"
                  easing="linear"
                />
                <p class="count-number-mo">%</p>
              </div>
              <div class="count-text-mo">訂單評估效率</div>
            </div>
          </div>
        </div>

        <p class="statistics-mo">資料統計截至 2024 年 5 月</p>
        <img
          style="width: 100%; margin-bottom: -2px"
          src="@/assets/images/desktop/products/Vector1.svg"
          alt="灰底弧度"
        />
      </div>
    </section>

    <!-- 會員回饋 -->
    <section style="position: relative">
      <div class="gray-square-bg-mo">
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 60px;
            padding-bottom: 48px;
          "
        >
          <img src="@/assets/images/desktop/products/quote-left.svg" alt="" />
          <p class="feedback-results-mo" style="">會員回饋</p>
          <img src="@/assets/images/desktop/products/quote-right.svg" alt="" />
        </div>

        <!-- 輪播 -->
        <div class="omg-carousel-mo">
          <b-carousel
            id="carousel-1"
            v-model="slide"
            :interval="4000"
            controls
            indicators
            background="#ababab"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
            class="carousel-one-mo"
          >
            <!-- 第一 -->
            <b-carousel-slide class="custom-carousel-slide-mo">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <img
                  style="width: 75px"
                  src="@/assets/images/desktop/products/8.png"
                  alt=""
                />
                <img
                  style="width: 81px; margin-top: -5px"
                  src="@/assets/images/desktop/products/red.svg"
                  alt=""
                />
                <p class="photo-text-name-mo">盧小姐</p>
                <p class="photo-text-position-mo">上班族</p>
                <p class="explain-mo">
                  服務親切、有耐心，詢問問題都會仔細回覆，代購的商品若有問題，也都會協助處理，很推薦喔❤️
                </p>
                <p class="date-mo">2021.03.24</p>
              </div>
            </b-carousel-slide>

            <!-- 第二 -->
            <b-carousel-slide class="custom-carousel-slide-mo">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <img
                  style="width: 75px"
                  src="@/assets/images/desktop/products/7.png"
                  alt=""
                />
                <img
                  style="width: 81px; margin-top: -5px"
                  src="@/assets/images/desktop/products/red.svg"
                  alt=""
                />
                <p class="photo-text-name-mo">林小姐</p>
                <p class="photo-text-position-mo">插畫家</p>
                <p class="explain-mo">
                  服務好！速度快 價格合理, 專注於批貨平台 👍
                </p>
                <p class="date-mo">2022.07.28</p>
              </div>
            </b-carousel-slide>

            <!-- 第三 -->
            <b-carousel-slide class="custom-carousel-slide-mo">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <img
                  style="width: 75px"
                  src="@/assets/images/desktop/products/1.png"
                  alt=""
                />
                <img
                  style="width: 81px; margin-top: -5px"
                  src="@/assets/images/desktop/products/red.svg"
                  alt=""
                />
                <p class="photo-text-name-mo">王先生</p>
                <p class="photo-text-position-mo">學生</p>
                <p class="explain-mo">
                  之前東西卡在海關嚇死 還好客服人員有幫忙解決 很感謝
                </p>
                <p class="date-mo">2023.01.13</p>
              </div>
            </b-carousel-slide>

            <!-- 第四 -->
            <b-carousel-slide class="custom-carousel-slide-mo">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <img
                  style="width: 75px"
                  src="@/assets/images/desktop/products/11.png"
                  alt=""
                />
                <img
                  style="width: 81px; margin-top: -5px"
                  src="@/assets/images/desktop/products/golden.svg"
                  alt=""
                />
                <p class="photo-text-name-mo">謝先生</p>
                <p class="photo-text-position-mo">批發業</p>
                <p class="explain-mo">
                  代購經驗都很好,店家也很照顧我們,非常感謝!
                </p>
                <p class="date-mo">2022.12.10</p>
              </div>
            </b-carousel-slide>

            <!-- 第五 -->
            <b-carousel-slide class="custom-carousel-slide-mo">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <img
                  style="width: 75px"
                  src="@/assets/images/desktop/products/5.png"
                  alt=""
                />
                <img
                  style="width: 81px; margin-top: -5px"
                  src="@/assets/images/desktop/products/white.svg"
                  alt=""
                />
                <p class="photo-text-name-mo">張先生</p>
                <p class="photo-text-position-mo">上班族</p>
                <p class="explain-mo">
                  有效溝通，且都會密切跟消費者與廠商聯繫！讓人十分安心
                </p>
                <p class="date-mo">2021.05.16</p>
              </div>
            </b-carousel-slide>

            <!-- 第六 -->
            <b-carousel-slide class="custom-carousel-slide-mo">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <img
                  style="width: 75px"
                  src="@/assets/images/desktop/products/2.png"
                  alt=""
                />
                <img
                  style="width: 81px; margin-top: -5px"
                  src="@/assets/images/desktop/products/golden.svg"
                  alt=""
                />
                <p class="photo-text-name-mo">陳先生</p>
                <p class="photo-text-position-mo">批發業</p>
                <p class="explain-mo">
                  我妹妹介紹的!滿好用的!回覆也很快!買到變常客哈!!
                </p>
                <p class="date-mo">2023.01.13</p>
              </div>
            </b-carousel-slide>
          </b-carousel>
        </div>
      </div>
    </section>

    <!-- 最後一頁 -->
    <section
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
      "
    >
      <p class="Finally-content-mo">
        無論何時何地，<br />
        我們都是您<span style="color: #3c5bff">值得信賴</span>的<br />
        1688代買夥伴
      </p>
      <img
        class="chat-picture-mo"
        src="@/assets/images/mobile/advantage/Group 3919.svg"
        alt=""
      />
      <img
        class="Finally-picture-mo"
        src="@/assets/images/desktop/recommend/Group 3875.svg"
        alt=""
      />

      <GoChatBot class="Finally-button-pointer-mo2" />
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  mounted() {},

  methods: {
    theFormat(value) {
      return value.toFixed(1);
    },

    randomInRange(range) {
      return Math.random() * range;
    },

    visibilityChanged(isVisible, entry) {
      if (isVisible) {
        this.$refs.number1.restart();
        this.$refs.number2.restart();
        this.$refs.number3.restart();
        this.$refs.number4.restart();
      }
    },
  },
};
</script>

<style scope>
.blue-background-mo {
  background: #5974ff;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.count-content-mo {
  background-color: #fff;
  width: 343px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 16px;
}

.service-results-mo {
  font-weight: 600;
  font-size: 26px;
  color: #ffffff;
  white-space: nowrap;
  margin: 0 23px;
  margin-top: 8px;
}

.count-content-line-mo {
  border: 0.8px solid #ced4da;
  width: 280px;
  margin-top: 36px;
  margin-bottom: 36px;
}

.count-number-mo {
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  color: #3c5bff;
}

.count-text-mo {
  font-size: 24px;
  color: #868e96;
}

.statistics-mo {
  font-size: 18px;
  color: #f8f9fa;
  margin-top: 40px;
  margin-bottom: 100px;
}

/* 會員回饋  */
.gray-square-bg-mo {
  background-color: #f1f3fa;
  position: relative;
}

#carousel-1 {
  background: none !important;
}

.partner-title {
  font-size: 38px;
  color: #343a40;
  font-weight: 600;
  text-align: center;
}

.carousel-one .custom-carousel-slide {
  height: 258px;
  background: #fff !important;
  border-radius: 30px;
}

.photo-text {
  display: flex;
  max-width: 4rem;
}

.photo-text-name {
  font-size: 22px;
  color: #212529;
  font-weight: 600;
  text-align: left;
  line-height: 0 !important;
}

.photo-text-position {
  font-size: 16px;
  color: #868e96;
  text-align: left;
}

.explain {
  font-size: 16px;
  color: #343a40;
  text-align: left;
}

.date {
  font-size: 16px;
  color: #868e96;
  text-align: right;
  line-height: 0 !important;
}

.carousel-one-mo .carousel-indicators .active {
  background-color: #5974ff !important;
}

.carousel-one-mo .carousel-indicators li {
  background-color: #b1bdff !important;
}

.carousel-one-mo .carousel-control-next-icon,
.carousel-one-mo .carousel-control-prev-icon {
  background-color: #5974ff !important;
  background-size: 50% 50% !important;
  border-radius: 50%;
  width: 40px !important;
  height: 40px !important;
}

.feedback-results-mo {
  font-weight: 600;
  font-size: 26px;
  color: #343a40;
  white-space: nowrap;
  margin: 0 24px;
}

.custom-carousel-slide-mo {
  height: 337px;
  background: #fff !important;
  border-radius: 30px;
}

.omg-carousel-mo {
  width: 343px;
  margin: 0 auto;
  padding-bottom: 124px;
}

.omg-carousel-mo .carousel-caption {
  position: static !important;
}

.photo-text-name-mo {
  font-size: 20px;
  color: #212529;
  font-weight: 600;
  text-align: left;
  line-height: 0 !important;
  padding-top: 21px;
}

.photo-text-position-mo {
  font-size: 14px;
  color: #868e96;
  padding-top: 8px;
}

.explain-mo {
  font-size: 14px;
  color: #343a40;
  width: 221px;
}

.date-mo {
  font-size: 14px;
  color: #868e96;
  line-height: 0 !important;
  margin-top: 10px;
}

/* 最後一頁 */
.Finally-picture-mo {
  width: 185%;
}

.Finally-content-mo {
  font-weight: 500;
  font-size: 20px;
  color: #343a40;
  text-align: center;
  margin-top: 76px;

  line-height: 40px;
}

.chat-picture-mo {
  width: 85%;
}

.Finally-button-pointer-mo2 {
  padding-top: 40px;
  padding-bottom: 80px;
  cursor: pointer;
  width: 221px;
}
</style>
