<template>
  <div>
    <div v-if="isMobile == 'yes'">
      <MobileAppHeader />
      <RouterView name="mobile" :isMobile="isMobile" />
      <!-- 刪除目錄的 footer -->
      <MobileAppFooter v-if="shouldShowFooter" />
    </div>
    <div v-else>
      <DesktopAppHeader />
      <RouterView name="desktop" :isMobile="isMobile" />
      <DesktopAppFooter />
    </div>
  </div>
</template>

<script>
import { isMobile } from "mobile-device-detect";
// header and footer
import DesktopAppHeader from "@/layout/desktop/AppHeader";
import DesktopAppFooter from "@/layout/desktop/AppFooter";
import MobileAppHeader from "@/layout/mobile/AppHeader";
import MobileAppFooter from "@/layout/mobile/AppFooter";
import { RouterView } from "vue-router";

export default {
  components: {
    DesktopAppHeader,
    DesktopAppFooter,
    MobileAppHeader,
    MobileAppFooter,
  },
  data() {
    return {
      isMobile: isMobile ? "yes" : "no",
      fullWidth: 0,
      fullHeight: 0,
    };
  },

  // 刪除目錄的 footer
  computed: {
    shouldShowFooter() {
      return this.$route.name !== "tableOfContents";
    },
  },

  watch: {
    fullWidth: function () {
      if (this.fullWidth <= 1059) {
        this.isMobile = "yes";
      } else {
        this.isMobile = "no";
      }
    },
  },
  mounted() {
    const vm = this;
    vm.fullWidth = window.innerWidth;
    vm.fullHeight = window.innerHeight;
    window.onresize = () => {
      vm.fullWidth = window.innerWidth;
      vm.fullHeight = window.innerHeight;
    };
  },
};
</script>
