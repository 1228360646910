import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import "@/index.css";
import mdiVue from "mdi-vue/v3";
import * as mdijs from "@mdi/js";
// import './registerServiceWorker'
// import * as firebase from "firebase";
// import VueFirebase from 'vue-firebase'
// import UUID from "vue-uuid";
// import VueToast from "vue-toast-notification";
// import "vue-toast-notification/dist/index.css";
// import VueCarousel from "vue-carousel";
// import VueLocalStorage from "vue-localstorage";
// import appodal from "vue-js-modal";
// import VueSelectImage from "vue-select-image";
import { DropdownPlugin } from "bootstrap-vue";
import { FormPlugin } from "bootstrap-vue";
import BootstrapVue from "bootstrap-vue";
import { AlertPlugin } from "bootstrap-vue";
import { CardPlugin } from "bootstrap-vue";
import { FormRadioPlugin } from "bootstrap-vue";
import { FormInputPlugin } from "bootstrap-vue";
import { FormCheckboxPlugin } from "bootstrap-vue";
import { FormTextareaPlugin } from "bootstrap-vue";

// import ToggleButton from "vue-js-toggle-button";

// import VueClipboard from "vue-clipboard2";
// import VueAnalytics from 'vue-analytics';

// import VueFilter from "vue-filter";
// import VueLazyload from "vue-lazyload";
// import VueLoading from "vue-loading-overlay";
// import "vue-loading-overlay/dist/vue-loading.css";
import { CollapsePlugin } from "bootstrap-vue";
import { AvatarPlugin } from "bootstrap-vue";
import { ImagePlugin } from "bootstrap-vue";
import { InputGroupPlugin } from "bootstrap-vue";
import { TablePlugin } from "bootstrap-vue";
import AOS from "aos";
import "aos/dist/aos.css";

import VueObserveVisibility from "vue-observe-visibility";
import "normalize.css/normalize.css";

const app = createApp(App, {
  mounted() {
    AOS.init(), document.dispatchEvent(new Event("render-event"));
  },
});

app.use(VueObserveVisibility);

// app.use(VueLazyload, {
//   preLoad: 1.3,
//   error: "/img/error.gif",
//   loading: "/img/icons/loading.gif",
//   attempt: 3,
//   throttleWait: 5000,
// });

// app.use(VueFilter);
// app.use(VueAnalytics, {
//   id: ["UA-146833596-1", "AW-700538278"],
//   router,
//   autoTracking: {
//     pageviewOnLoad: false,
//   },
// });
// app.use(VueClipboard);
app.use(FormTextareaPlugin);
// app.use(ToggleButton);
app.use(FormCheckboxPlugin);
app.use(FormInputPlugin);
app.use(FormRadioPlugin);
app.use(CardPlugin);
app.use(AlertPlugin);
app.use(BootstrapVue);
app.use(FormPlugin);
app.use(CollapsePlugin);
app.use(AvatarPlugin);
app.use(TablePlugin);
app.use(ImagePlugin);
// add stylesheet
// require("vue-select-image/dist/vue-select-image.css");

// app.use(VueSelectImage);
app.use(DropdownPlugin);
// app.use(appodal, {
//   dialog: true,
// });
// app.use(VueLocalStorage);
// app.use(VueCarousel);
// app.use(UUID);
// app.use(VueLoading);
app.use(InputGroupPlugin);

app.prototype.$liff = window.liff;
app.config.productionTip = false;

// app.use(UUID);
// app.use(VueToast);

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面meta */
  if (to.meta.content) {
    let head = document.getElementsByTagName("head");
    let meta = document.createElement("meta");
    meta.content = to.meta.content;
    head[0].appendChild(meta);
  }
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

app
  .use(router)
  .use(store)
  .use(mdiVue, {
    icons: mdijs,
  })
  .mount("#app");

import BlogSubscribeMobile from "@/components/BlogSubscribeMobile.vue";
import BlogSubscribeDesktop from "@/components/BlogSubscribeDesktop.vue";
app.component("blog-subscribe-mobile", BlogSubscribeMobile);
app.component("blog-subscribe-desktop", BlogSubscribeDesktop);
