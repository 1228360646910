<template>
  <div class="footer-mo">
    <div class="full-width-gray-line-mo"></div>

    <div style="margin-left: 16px">
      <div style="margin-bottom: 36px; margin-top: 48px">
        <router-link to="/">
          <img
            style="width: 104px"
            src="@/assets/images/mobile/footer/basic_logo_cn_l 1.jpg"
            alt="chatxbuy"
          />
        </router-link>
      </div>

      <div class="footer_herf" style="margin-bottom: 24px">
        <router-link
          style="text-decoration: none; color: inherit"
          to="/page/prohibit"
        >
          <span class="button-pointer footer_text">禁運商品</span>
        </router-link>
      </div>

      <div class="footer_herf" style="margin-bottom: 24px">
        <router-link
          style="text-decoration: none; color: inherit"
          to="/page/termsService"
        >
          <span class="button-pointer footer_text">服務條款</span>
        </router-link>
      </div>

      <div class="footer_herf" style="margin-bottom: 24px">
        <router-link
          style="text-decoration: none; color: inherit"
          to="/page/privacyPolicy"
        >
          <span class="button-pointer footer_text">隱私權政策</span>
        </router-link>
      </div>
    </div>

    <div
      style="
        display: flex;
        flex-direction: column;
        background-color: #2a40b3;
        width: 100vw;
        padding-left: 16px;
      "
    >
      <div style="display: flex; margin-top: 40px; margin-bottom: 24px">
        <div style="padding-right: 24px">
          <a href="https://www.facebook.com/chatXbuy/" target="_blank">
            <img src="@/assets/images/mobile/footer/Negative-fb.svg" alt="FB" />
          </a>
        </div>

        <div>
          <a href="https://www.instagram.com/chatxbuy/" target="_blank">
            <img src="@/assets/images/mobile/footer/Negative-ig.svg" alt="ig" />
          </a>
        </div>
      </div>

      <div class="footer-blue-footer-mo">
        代買幫商行<br />
        客服時段：週一～週五 09:30~18:30<br />
        Copyright© 2024 chatxbuy All Rights Reserved.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "app-footer",
  data() {
    return {};
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    goLnb() {
      window.open("https://chatxbuyapp.page.link/lnbwebpage");
    },
    // goBlog() {
    //   this.$router.push("page/blogList");
    // },
    goBear() {
      window.open("https://pse.is/3qbann");
    },
    goEZBuy() {
      window.open("https://chatbuy.pros.is/3wvhel");
    },
  },
};
</script>
<style scope>
.footer-mo {
  height: 370px;
  width: 40%;
}

.full-width-gray-line-mo {
  width: 100vw;
  height: 1px;
  background-color: #dee2e6;
}

.footer-blue-footer-mo {
  color: #fff;
  font-size: 14px;
  line-height: 28px;
  padding-bottom: 40px;
}
</style>
