import { createStore } from "vuex";

const store = createStore({
  state: {
    apiUrl: "https://biz-api.herokuapp.com/",
    isMoreMomy: false,
    prodInfos: [],
    newprodInfos: [],
    recommendInfo: [],
    ignoreBaseLine: false,
    addBuyUrl: "",
  },
  mutations: {},
  actions: {},
});

export default store;
