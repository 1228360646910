<template>
  <div>
    <section class="bl-bg1-Trouble-mo">
      <p class="blue-text-mo">
        這些煩惱<br />
        讓1688專業代買
        <span style="color: #99f9f9; padding-left: 5px; padding-right: 5px"
          >CHATxBUY</span
        ><br />
        通通幫你搞定！
      </p>
      <div class="bl-ellipse-Trouble-mo"></div>
    </section>

    <section>
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <div style="display: flex; padding-top: 90px">
          <img src="@/assets/images/mobile/advantage/right.svg" alt="" />
          <p class="feature-content-mo">
            CHATxBUY <span style="color: #3c5bff">5</span> 大特色
          </p>
          <img src="@/assets/images/mobile/advantage/left.svg" alt="" />
        </div>
        <div class="sidehead-mo">
          化繁為簡，為您提供 1688<br />
          專業一條龍代買服務
        </div>
      </div>

      <div style="padding-top: 60px; padding-bottom: 60px">
        <div class="step-5-box-mo">
          <img src="@/assets/images/desktop/advantage/Group 3862.svg" />
          <div style="display: flex; flex-direction: column">
            <p class="p-content-mo">聊天機器人下單</p>
            <p class="p-content2-mo">免下載APP，手機一鍵搞定<br />隨時可下單</p>
          </div>
        </div>
        <div class="step-5-box-mo">
          <img src="@/assets/images/desktop/advantage/Group 3863.svg" />
          <div style="display: flex; flex-direction: column">
            <p class="p-content-mo">專業 1688 代購經驗</p>
            <p class="p-content2-mo">
              台灣團隊，多年代購經驗<br />細心掌握每個流程
            </p>
          </div>
        </div>

        <div class="step-5-box-mo">
          <img src="@/assets/images/desktop/advantage/Group 3864.svg" />
          <div style="display: flex; flex-direction: column">
            <p class="p-content-mo">費用明確清楚</p>
            <p class="p-content2-mo">
              收費結構透明<br />收到報價後，滿意再付款
            </p>
          </div>
        </div>

        <div class="step-5-box-mo">
          <img src="@/assets/images/desktop/advantage/Group 3865.svg" />
          <div style="display: flex; flex-direction: column">
            <p class="p-content-mo">訂單進度一目了然</p>
            <p class="p-content2-mo">
              全程跟進訂單進度<br />服務時間內，即時溝通
            </p>
          </div>
        </div>
        <div class="step-5-box-mo">
          <img src="@/assets/images/desktop/advantage/Group 3866.svg" />
          <div style="display: flex; flex-direction: column">
            <p class="p-content-mo">協助售後溝通</p>
            <p class="p-content2-mo">
              到貨後如有問題<br />協助您與 1688 廠商溝通
            </p>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div>
        <img
          style="width: 100%; height: 100%"
          src="@/assets/images/desktop/process/Vector.svg"
          alt="灰底"
        />

        <div class="gray-bk-mo">
          <div
            style="
              position: absolute;
              transform: translateX(-50%);
              left: 50%;
              top: 0px;
            "
          >
            <div
              style="
                display: flex;
                padding-top: 66px;
                padding-bottom: 36px;
                justify-content: center;
              "
            >
              <img src="@/assets/images/mobile/advantage/right.svg" alt="" />
              <p class="step-title1-mo">代買流程</p>
              <img src="@/assets/images/mobile/advantage/left.svg" alt="" />
            </div>

            <div style="">
              <div class="box-mo">
                <div class="step-number-photo-mo">
                  <div class="Step-number-mo">
                    <p>Step.1</p>
                    <p>使用</p>
                  </div>
                  <img
                    src="@/assets/images/desktop/process/use.svg"
                    alt="使用"
                  />
                </div>
                <p>進入Messenger，點擊開始，開啟代買服務。</p>
              </div>

              <img
                class="arrow-mo"
                src="@/assets/images/mobile/advantage/Group 3918.svg"
                alt="箭頭"
              />

              <div class="box-mo">
                <div class="step-number-photo-mo">
                  <div class="Step-number-mo">
                    <p>Step.2</p>
                    <p>委託</p>
                  </div>
                  <img
                    src="@/assets/images/desktop/process/Group 3830.svg"
                    alt="委託"
                  />
                </div>
                <p>填寫委託單，貼上商品連結，填寫好收件資訊，並送出申請。</p>
              </div>

              <img
                class="arrow-mo"
                src="@/assets/images/mobile/advantage/Group 3918.svg"
                alt="箭頭"
              />

              <div class="box-mo">
                <div class="step-number-photo-mo">
                  <div class="Step-number-mo">
                    <p>Step.3</p>
                    <p>報價</p>
                  </div>
                  <img
                    src="@/assets/images/desktop/process/Group 3868.svg"
                    alt="報價"
                  />
                </div>
                <p>收到委託單，將在兩個工作天內完成估價。</p>
              </div>

              <img
                class="arrow-mo"
                src="@/assets/images/mobile/advantage/Group 3918.svg"
                alt="箭頭"
              />

              <div class="box-mo">
                <div class="step-number-photo-mo">
                  <div class="Step-number-mo">
                    <p>Step.4</p>
                    <p>付款</p>
                  </div>
                  <img
                    src="@/assets/images/desktop/process/Group 3871.svg"
                    alt="付款"
                  />
                </div>
                <p>我們會提供您匯款資訊，請於 24 小時內完成付款。</p>
              </div>
              <img
                class="arrow-mo"
                src="@/assets/images/mobile/advantage/Group 3918.svg"
                alt="箭頭"
              />

              <div class="box-mo">
                <div class="step-number-photo-mo">
                  <div class="Step-number-mo">
                    <p>Step.5</p>
                    <p>寄送</p>
                  </div>
                  <img
                    src="@/assets/images/desktop/process/Group 3870.svg"
                    alt="寄送"
                  />
                </div>
                <p>
                  1688 廠商會進行出貨，同時，請也您事先做好EZ
                  WAY易利委申報，以利加快商品通關。
                </p>
              </div>
              <img
                class="arrow-mo"
                src="@/assets/images/mobile/advantage/Group 3918.svg"
                alt="箭頭"
              />

              <div class="box-mo">
                <div class="step-number-photo-mo">
                  <div class="Step-number-mo">
                    <p>Step.6</p>
                    <p>取貨</p>
                  </div>
                  <img
                    src="@/assets/images/desktop/process/Group 3869.svg"
                    alt="取貨"
                  />
                </div>
                <p>
                  開箱時，別忘記開箱時記得拍照或錄影，後續有爭議時，以利向1688
                  廠商溝通唷！
                </p>
              </div>
            </div>
          </div>
        </div>
        <img
          style="width: 100%; height: 100%; margin-top: -2px"
          src="@/assets/images/desktop/process/Vector2.svg"
          alt="灰底"
        />
      </div>
    </section>

    <section>
      <div style="margin-top: 80px; position: relative">
        <p class="process-content-mo">
          只要想在1688上購物 <br />
          都歡迎使用 <span style="color: #3c5bff">CHATxBUY</span>
        </p>

        <div style="position: relative; z-index: 2">
          <div
            style="display: flex; flex-direction: column; align-items: center"
          >
            <img
              class=""
              src="@/assets/images/desktop/process/people1.svg"
              alt="個人買家"
            />
            <p class="people-content-mo">個人買家</p>
          </div>

          <div
            style="display: flex; flex-direction: column; align-items: center"
          >
            <img
              class=""
              src="@/assets/images/desktop/process/people2.svg"
              alt="1688批貨小白"
            />
            <p class="people-content-mo">1688批貨小白</p>
          </div>

          <div
            style="display: flex; flex-direction: column; align-items: center"
          >
            <img
              class=""
              src="@/assets/images/desktop/process/people3.svg"
              alt="1688批貨老鳥"
            />
            <p class="people-content-mo">1688批貨老鳥</p>
          </div>

          <div
            style="display: flex; flex-direction: column; align-items: center"
          >
            <img
              class=""
              src="@/assets/images/desktop/process/people4.svg"
              alt="小資商家"
            />
            <p class="people-content-mo">小資商家</p>
          </div>
        </div>
        <div class="wh-ellipse-mo"></div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {
    goChatbot() {
      gtag("event", "Index_click", {
        action: "goChatbot",
        page: "process",
      });
      if (localStorage.utm_ref != null) {
        window.open("https://m.me/chatXbuy?ref=" + localStorage.utm_ref);
      } else {
        window.open("https://m.me/chatXbuy");
      }
    },
  },
  mounted() {},
};
</script>

<style scope>
.bl-ellipse-Trouble-mo {
  width: 100%;
  height: 100px;
  background-color: #5974ff;
  border-radius: 50%;
  position: absolute;
  top: 81px;
  z-index: 1;
}

.blue-text-mo {
  color: #ffffff;
  font-size: 20px;
  position: relative;
  top: 30%;
  text-align: center;
  padding-top: 43px;
  z-index: 2;
}

.bl-bg1-Trouble-mo {
  background: #5974ff;
  position: relative;
}

.feature-content-mo {
  font-weight: 800;
  font-size: 26px;
  transform: scaleY(1.05);
  color: #343a40;
  margin: 0;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 10px;
}

.sidehead-mo {
  font-weight: 500;
  font-size: 18px;
  color: #495057;
  padding-top: 24px;
  text-align: center;
}

.step-5-box-mo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.p-content-mo {
  color: #5974ff;
  font-weight: bold;
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 13px;
  margin: 0;
  text-align: center;
}

.p-content2-mo {
  color: #495057;
  font-size: 15px;
  text-align: center;
}

/* 代買流程 */
.gray-bk-mo {
  background-color: #f1f3fa;
  width: 100%;
  height: 1634px;
  position: relative;
  margin-top: -1px;
}

.step-title1-mo {
  font-weight: 800;
  font-size: 26px;
  transform: scaleY(1.05);
  color: #343a40;
  margin: 0;
  padding-left: 24px;
  padding-right: 24px;
}

.box-mo {
  border: 1px solid #ced6ff;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px;
  max-width: 250px;
  box-shadow: 0 0.5px 30px 0 rgba(241, 238, 238, 0.496);
  min-width: 242px;
  min-height: 200px;
}

.step-number-photo-mo {
  display: flex;
  justify-content: space-between;
}

.step-number-photo-mo > img {
  margin-top: -20px;
}

.Step-number-mo > p:nth-child(1) {
  font-weight: 600;
  font-size: 16px;
  color: #2aa5ff;
}

.Step-number-mo > p:nth-child(2) {
  font-weight: 600;
  font-size: 24px;
  color: #060e57;
  margin-top: -1rem;
}

.box > p {
  font-size: 15px;
  color: #495057;
}

.arrow-mo {
  display: block;
  margin: 0 auto;
  margin-bottom: 6px;
}

/* 4個人 */
.process-content-mo {
  font-size: 24px;
  color: #343a40;
  height: auto;
  text-align: center;
  font-weight: 500;
}

.people-content-mo {
  font-size: 20px;
  color: #212529;
}

.wh-ellipse-mo {
  width: 100%;
  height: 190px;
  background-color: #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 940px;
  z-index: 1;
}
</style>
