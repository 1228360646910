<script setup>
const goChatbot = () => {
  gtag("event", "Index_click", { action: "goChatbot", page: "hero" });
  if (localStorage.utm_ref != null) {
    window.open("https://m.me/chatXbuy?ref=" + localStorage.utm_ref);
  } else {
    window.open("https://m.me/chatXbuy");
  }
};
</script>

<template>
  <img
    style="display: block; width: 221px; margin-inline: auto; cursor: pointer"
    @click="goChatbot"
    src="@/assets/images/mobile/advantage/Frame 624684.png"
    alt="代買幫"
  />
</template>
