<template>
  <div
    style="display: flex; justify-content: space-between; align-items: center"
  >
    <b-navbar type="dark" variant="white">
      <b-navbar-brand>
        <div style="width: 125px">
          <div style="width: 125px; margin: 0 auto">
            <router-link to="/">
              <img
                style="width: 104px"
                src="@/assets/images/mobile/header/basic_logo_cn_l 1.jpg"
                alt="chatxbuy"
              />
            </router-link>
          </div>
        </div>
      </b-navbar-brand>
    </b-navbar>
    <img
      :style="menuImageStyle"
      :src="menuImage"
      alt=""
      @click="navigateToTableOfContents"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuImage: require("@/assets/images/mobile/header/menu.svg"),
    };
  },

  mounted() {
    this.updateMenuImage();
  },

  watch: {
    $route() {
      this.updateMenuImage();
    },
  },

  // 移動照片
  computed: {
    menuImageStyle() {
      if (
        this.menuImage === require("@/assets/images/mobile/header/menu.svg")
      ) {
        return {
          marginRight: "22px",
          transform: "translateX(-0px)",
        };
      } else {
        return {
          marginRight: "22px",
          transform: "translateX(-5px)",
        };
      }
    },
  },

  methods: {
    navigateToTableOfContents() {
      if (this.$route.path === "/page/tableOfContents") {
        this.$router.back();
      } else {
        this.$router.push("/page/tableOfContents");
      }
    },
    updateMenuImage() {
      if (this.$route.path === "/page/tableOfContents") {
        this.menuImage = require("@/assets/images/mobile/header/Vector.svg");
      } else {
        this.menuImage = require("@/assets/images/mobile/header/menu.svg");
      }
    },
    goLnb() {
      window.open("https://chatxbuyapp.page.link/lnbwebpage");
      this.$refs.ddown.visible = false;
    },
  },

  beforeUnmount() {
    this.$root.$off("bv::dropdown::hidden");
    this.$root.$off("bv::dropdown::shown");
  },
};
</script>

<style scope>
.dropdown-toggle:after {
  content: none;
}

.dropdown-item {
  display: inline-flex !important;
  padding: 0px !important;
}

.dropdown-menu {
  width: 335px;
  background-color: #f0f0f0;
  text-align: center !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f0f0f0;
}

.nav-dropdown-menu {
  background-image: url("../../assets/images/mobile/header/menu.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.navbar-expand .navbar-nav .nav-link {
  padding-bottom: 1px;
}

.item-text {
  font-family: "PingFang TC";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #212529;
}
</style>
