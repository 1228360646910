<template>
  <div>
    <section>
      <div class="gradient-layer-mo">
        <div style="text-align: left">
          <p class="hero-title-mo">代買幫 CHATxBUY</p>
          <p class="hero-title2-mo">專業阿里巴巴 1688 代買</p>
          <p class="hero-content-mo">
            從下單、賣家溝通、國際集運到商品抵達，<br />代買幫都幫您
            一手包辦。<br />讓您可以享受無憂的 1688 代買體驗！
          </p>
        </div>

        <img
          class="global-mo"
          src="@/assets/images/mobile/hero/Group 3913.svg"
          alt="代買幫"
        />

        <img
          style="margin-top: -600px; margin-left: 800px"
          src="@/assets/images/desktop/hero/Vector.svg"
          alt="漸層白底"
        />
        <img
          style="top: 518px; position: absolute"
          src="@/assets/images/desktop/hero/Group.svg"
          alt="下圓弧"
        />

        <img
          class="transparent-bg3-mo"
          @click="goChatbot"
          src="@/assets/images/desktop/hero/Frame 624684.png"
          alt="代買幫"
        />
      </div>
    </section>

    <section>
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 10px;
        "
      >
        <p class="do-you-want-buy-text-mo">
          想在1688買東西，<br />卻困難重重嗎？
        </p>
        <img
          style="padding-bottom: 41px"
          src="@/assets/images/mobile/advantage/Group 3916.svg"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { isMobile } from "mobile-device-detect";
export default {
  components: {},
  data() {
    return {
      isMobile: isMobile ? "yes" : "no",
    };
  },
  methods: {
    goChatbot() {
      gtag("event", "Index_click", {
        action: "goChatbot",
        page: "hero",
      });
      if (localStorage.utm_ref != null) {
        window.open("https://m.me/chatXbuy?ref=" + localStorage.utm_ref);
      } else {
        window.open("https://m.me/chatXbuy");
      }
    },
  },
  mounted() {},
};
</script>

<style scope>
.gradient-layer-mo {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  background: linear-gradient(to bottom right, #91acf9, #3c5bff);
  width: 100%;
  height: 660px;
  position: relative;
  overflow: hidden;
}

.hero-title-mo {
  font-weight: bold;
  font-size: 30px;
  margin-top: 50px;
}

.hero-title2-mo {
  font-weight: bold;
  color: #ffffff;
  font-size: 20px;
  margin-top: 19px;
}

.hero-content-mo {
  font-weight: Medium;
  color: #ffffff;
  font-size: 16px;
  height: auto;
  margin-top: 24px;
}

.global-mo {
  margin-top: 27px;
  margin-left: 30px;
  margin-bottom: 28px;
}

.transparent-bg3-mo {
  width: 220px;
  position: absolute;
  top: 575px;
}

.do-you-want-buy-text-mo {
  text-align: center;
  font-size: 20px;
  margin-top: 65px;
}
</style>
