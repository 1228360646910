<template>
  <div>
    <div v-if="isMobile == 'yes'">
      <MobileAppHeader />
      <MobileHero />
      <MobileAdvantage />
      <!-- <MobileProcess/> -->
      <MobileProducts />
      <MobileCount />
      <!-- <MobileRecommend/> -->
      <MobileAppFooter />
    </div>
    <div v-else>
      <DesktopAppHeader />
      <DesktopHero />
      <DesktopAdvantage />
      <DesktopProcess />
      <DesktopProducts />
      <!-- <DesktopCount/> -->
      <DesktopRecommend />
      <DesktopAppFooter />
    </div>
  </div>
</template>

<script>
import { isMobile } from "mobile-device-detect";
// header and footer
import DesktopAppHeader from "@/layout/desktop/AppHeader";
import DesktopAppFooter from "@/layout/desktop/AppFooter";
import MobileAppHeader from "@/layout/mobile/AppHeader";
import MobileAppFooter from "@/layout/mobile/AppFooter";

// Mobile
import MobileHero from "./mobile/Hero.vue";
import MobileAdvantage from "./mobile/Advantage.vue";
// import MobileProcess from "./mobile/process.vue";
import MobileProducts from "./mobile/Products.vue";
import MobileCount from "./mobile/Count.vue";
// import MobileRecommend from "./mobile/recommend.vue";

// Desktop
import DesktopHero from "./desktop/Hero.vue";
import DesktopAdvantage from "./desktop/Advantage.vue";
import DesktopProcess from "./desktop/Process.vue";
import DesktopProducts from "./desktop/Products.vue";
// import DesktopCount from "./desktop/count.vue";
import DesktopRecommend from "./desktop/Recommend.vue";

import AOS from "aos";
import "aos/dist/aos.css";

export default {
  name: "components",
  components: {
    DesktopAppHeader,
    MobileAppHeader,
    DesktopAppFooter,
    MobileAppFooter,
    DesktopHero,
    DesktopAdvantage,
    DesktopProcess,
    DesktopProducts,
    // DesktopCount,
    DesktopRecommend,
    MobileHero,
    MobileAdvantage,
    // MobileProcess,
    MobileProducts,
    MobileCount,
    // MobileRecommend,
  },
  data() {
    return {
      isMobile: isMobile ? "yes" : "no",
      fullWidth: 0,
      fullHeight: 0,
    };
  },
  watch: {
    fullWidth: function () {
      if (this.fullWidth <= 1059) {
        this.isMobile = "yes";
      } else {
        this.isMobile = "no";
      }
    },
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.onScroll);
    const vm = this;
    vm.fullWidth = window.innerWidth;
    vm.fullHeight = window.innerHeight;
    window.onresize = () => {
      vm.fullWidth = window.innerWidth;
      vm.fullHeight = window.innerHeight;
    };
    vm.parseUTMData();
    console.log("utm_ref:", localStorage.utm_ref);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      AOS.refresh();
    },
    parseUTMData() {
      const vm = this;
      let utm_source = "";
      let utm_medium = "";
      let utm_content = "";

      if (vm.$route.query.utm_source != undefined) {
        utm_source = decodeURI(vm.$route.query.utm_source);
      }
      if (vm.$route.query.utm_medium != undefined) {
        utm_medium = decodeURI(vm.$route.query.utm_medium);
      }
      if (vm.$route.query.utm_content != undefined) {
        utm_content = decodeURI(vm.$route.query.utm_content);
      }
      if (utm_source == "" && utm_medium == "" && utm_content == "") {
        return null;
      } else {
        let utm_ref = utm_source + "_" + utm_medium + "_" + utm_content;
        localStorage.utm_ref = utm_ref;
        return utm_ref;
      }
    },
  },
};
</script>
