import { createWebHistory, createRouter } from "vue-router";

import Page from "./layout/page.vue";
import Home from "./views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/page",
    component: Page,
    children: [
      {
        path: "aboutUs",
        name: "aboutUs",
        components: {
          desktop: () => import("./views/desktop/AboutUs.vue"),
          mobile: () => import("./views/mobile/AboutUs.vue"),
        },
        meta: {
          title: "關於我們",
          content: "代買幫關於我們",
        },
        pathToRegexpOptions: {
          strict: true,
        },
      },
      {
        path: "freight",
        name: "freight",
        components: {
          desktop: () => import("./views/desktop/Fee.vue"),
          mobile: () => import("./views/mobile/Fee.vue"),
        },
        meta: {
          title: "費用說明",
          content: "代買幫費用說明",
        },
        pathToRegexpOptions: {
          strict: true,
        },
      },
      {
        path: "notice",
        name: "notice",
        components: {
          desktop: () => import("./views/desktop/Notice.vue"),
          mobile: () => import("./views/mobile/Notice.vue"),
        },
        meta: {
          title: "Q&A",
          content: "代買幫Q&A",
        },
        pathToRegexpOptions: {
          strict: true,
        },
      },
      {
        path: "prohibit",
        name: "prohibit",
        components: {
          desktop: () => import("./views/desktop/Prohibit.vue"),
          mobile: () => import("./views/mobile/Prohibit.vue"),
        },
        meta: {
          title: "禁運商品",
          content: "代買幫禁運商品",
        },
        pathToRegexpOptions: {
          strict: true,
        },
      },
      {
        path: "service",
        name: "service",
        components: {
          desktop: () => import("./views/desktop/Service.vue"),
          mobile: () => import("./views/mobile/Service.vue"),
        },
        meta: {
          title: "服務介紹",
          content: "代買幫服務介紹",
        },
        pathToRegexpOptions: {
          strict: true,
        },
      },
      {
        path: "blogList",
        name: "blogList",
        components: {
          desktop: () => import("./views/desktop/BlogList.vue"),
          mobile: () => import("./views/mobile/BlogList.vue"),
        },
        meta: {
          title: "部落格",
          content: "代買幫部落格",
        },
        pathToRegexpOptions: {
          strict: true,
        },
      },
      {
        path: "blogPage/:id",
        name: "blogPage",
        components: {
          desktop: () => import("./views/desktop/BlogPage.vue"),
          mobile: () => import("./views/mobile/BlogPage.vue"),
        },
        meta: {
          title: "部落格分頁",
          content: "代買幫部落格分頁",
        },
        pathToRegexpOptions: {
          strict: true,
        },
      },
      {
        path: "blogTopic",
        name: "blogTopic",
        components: {
          desktop: () => import("./views/desktop/BlogTopic.vue"),
          mobile: () => import("./views/mobile/BlogTopic.vue"),
        },
        meta: {
          title: "部落格類別分頁",
          content: "代買幫部落格類別分頁",
        },
        pathToRegexpOptions: {
          strict: true,
        },
      },
      {
        path: "termsService",
        name: "termsService",
        components: {
          desktop: () => import("./views/desktop/TermsService.vue"),
          mobile: () => import("./views/mobile/TermsService.vue"),
        },
        meta: {
          title: "服務條款",
          content: "代買幫服務條款",
        },
        pathToRegexpOptions: {
          strict: true,
        },
      },

      {
        path: "privacyPolicy",
        name: "privacyPolicy",
        components: {
          desktop: () => import("./views/desktop/PrivacyPolicy.vue"),
          mobile: () => import("./views/mobile/PrivacyPolicy.vue"),
        },
        meta: {
          title: "隱私權政策",
          content: "代買幫隱私權政策",
        },
        pathToRegexpOptions: {
          strict: true,
        },
      },
      {
        path: "tableOfContents",
        name: "tableOfContents",
        components: {
          mobile: () => import("./views/mobile/TableOfContents..vue"),
        },
        meta: {
          title: "目錄分頁",
          content: "代買幫目錄分頁",
        },
        pathToRegexpOptions: {
          strict: true,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
});

export default router;
